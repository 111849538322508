// Icon Input
//––––––––––––––––––––––––––––––––––––––––––––––––––

// variables
$icon-input-icon-class-prefix:  'fa-' !default;
$icon-input-icon-width:         2.65em !default;
$icon-input-margin-bottom:      $input-margin-bottom !default;

.icon-input {
  @include input-width;

  position: relative;
  display: inline-flex;
  margin-bottom: $icon-input-margin-bottom;
  font-size: $input-font-size;

  input {
    flex: 1 1 auto;
    margin: 0;
    padding-right: $icon-input-icon-width;
    font-size: 1em;
  }

  [class*="#{$icon-input-icon-class-prefix}"] {
    @include position($top: 0, $right: 0);

    width: $icon-input-icon-width;
    height: 100%;
    font-size: 1em;
    line-height: 1;
    text-align: center;
    opacity: 1;
  }

  [class*="#{$icon-input-icon-class-prefix}"]:before {
    @include position($top: 50%, $left: 0);

    width: 100%;
    margin: -0.5em 0;
    text-align: center;
  }
}
