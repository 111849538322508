// Tooltips
// because these are pseudo based they only work on elements that have content
//––––––––––––––––––––––––––––––––––––––––––––––––––

$tooltip-background:            color("black") !default;
$tooltip-text-color:            color("white") !default;
$tooltip-font-size:             90% !default;
$tooltip-padding:               0.5rem 1rem !default;
$tooltip-arrow-height:          6px !default;
$tooltip-border-radius:         $global-radius !default;
$tooltip-transition:            opacity $animation-speed-fast ease-in-out !default;

// add the zindex to your
$z-layers:                      map-merge(("tooltip": 100 ), $z-layers);

// base styles
[data-tooltip] {
  position: relative;
  cursor: pointer;

  // base content
  &::after {
    @include pseudo($content: attr(data-tooltip));

    z-index: zindex("tooltip");
    padding: $tooltip-padding;
    color: $tooltip-text-color;
    font-size: $tooltip-font-size;
    white-space: nowrap;
    background: $tooltip-background;
    border-radius: $tooltip-border-radius;
    opacity: 0;
    transition: $tooltip-transition;
    pointer-events: none;
  }

  // base arrow
  &::before {
    @include pseudo;

    z-index: zindex("tooltip");
    opacity: 0;
    transition: $tooltip-transition;
    pointer-events: none;
  }

  // base hover and always visible
  &:hover,
  &[data-tooltip-visible] {
    &::before,
    &::after {
      opacity: 1;
      pointer-events: auto;
    }
  }

  // directional tooltips up
  // default when no position defined
  &[data-tooltip-pos="up"],
  &:not([data-tooltip-pos]) {
    &::after {
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -10px);
    }

    &::before {
      @include css-triangle($tooltip-background, down, $tooltip-arrow-height, absolute);

      bottom: 100%;
      left: 50%;
      transform: translate(-50%, -10px + $tooltip-arrow-height);
    }
  }

  // directional tooltips down
  &[data-tooltip-pos='down'] {
    &::after {
      top: 100%;
      left: 50%;
      transform: translate(-50%, 10px);
    }

    &::before {
      @include css-triangle($tooltip-background, up, $tooltip-arrow-height, absolute);

      top: 100%;
      left: 50%;
      transform: translate(-50%, 10px - $tooltip-arrow-height);
    }
  }

  // directional tooltips left
  &[data-tooltip-pos='left'] {
    &::after {
      top: 50%;
      right: 100%;
      transform: translate(-10px, -50%);
    }

    &::before {
      @include css-triangle($tooltip-background, right, $tooltip-arrow-height, absolute);

      top: 50%;
      right: 100%;
      transform: translate(-10px, -50%);
    }
  }

  // directional tooltips right
  &[data-tooltip-pos='right'] {
    &::after {
      top: 50%;
      left: 100%;
      transform: translate(10px, -50%);
    }

    &::before {
      @include css-triangle($tooltip-background, left, $tooltip-arrow-height, absolute);

      top: 50%;
      left: 100%;
      transform: translate(10px, -50%);
    }
  }
}
