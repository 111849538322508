// Pseudo
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// pseudu
//
// useful for when styling :before or :after
// you neally always need these 3
//
// Usage:
// @include pseudo;
//
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  position: $pos;
  display: $display;
  content: $content;
}
