// Button Group
//––––––––––––––––––––––––––––––––––––––––––––––––––

// variables
$button-group-collapse-borders: false !default;

// base styles
.button-group {
  display: inline-flex;
  flex-direction: row;

  > .button {
    flex: 1 0 auto;
  }

  > .button:not(:first-child) {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    @if ($button-group-collapse-borders) {
      border-right: 0;
    }
  }
}
