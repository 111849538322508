// CSS Triangle
// ––––––––––––––––––––––––––––––––––––––––––––––––––

// css triangle
// you are just left to move it into position
//
// Usage:
// pointing down
// @include css-triangle(color("red"), down, 10px)
// also has rounded corners
// @include css-triangle(color("red"), down, 10px, $round: true)
//
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);

  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction == down {
    margin-top: 0 - round($size / 2.5);
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-left: $size solid transparent;
  }

  @else if $direction == up {
    margin-bottom: 0 - round($size / 2.5);
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    border-left: $size solid transparent;
  }

  @else if $direction == right {
    margin-right: -$size;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
  }

  @else if  $direction == left {
    margin-left: -$size;
    border-top: $size solid transparent;
    border-right: $size solid $color;
    border-bottom: $size solid transparent;
  }
}
